import React from "react"

import Layout from "../components/Layout"
import Grid from "../components/Grid"

import css from "./maintenance.module.scss"

import maintenanceImage from "../assets/images/maintenance.png"

const metadata = {
  title: "ReferralCandy is currently undergoing scheduled maintenance",
  description:
    "We will be back in no time and all referral activity will sync up once maintenance is complete",
  noindex: true,
}

// Removed suggested fix for this flash as it affects SEO by delaying meta tags construction,
// in particular the noindex flag to prevent indexing of the page from spiders
// Review again if needed future.

// Prevent flash of 404 during page redirect for client-only paths created in gatsby-node
// See: https://github.com/gatsbyjs/gatsby/issues/5329#issuecomment-484741119

const MaintenancePage = (props) => {
  return (
    <Layout metadata={metadata} pathname={props.location.pathname}>
      <Grid className={css.container} centeredClassName={css.centered}>
        <img src={maintenanceImage} alt="Maintenance" />
        <h1>
          Sorry for the inconvenience, ReferralCandy is currently undergoing
          scheduled maintenance
        </h1>
        <h3>
          {" "}
          We will be back in no time and all referral activity will sync up once
          maintenance is complete.
        </h3>
      </Grid>
    </Layout>
  )
}

export default MaintenancePage
